import React from "react";
import { FormGroup, Label } from "reactstrap";

import { TextField } from "@material-ui/core";
import { makeStyles } from '@mui/styles';

// import sendToEscrowProgressBar from "../sendToEscrow/SendToEscrowProgressBar"

import { useMoralis } from "react-moralis";

const useStyles = makeStyles((theme) => ({
  SearchBar: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
  //   marginLeft: theme.spacing(1),
    flex: 1,
  //   marginLeft: "10px",
    marginTop: "-10px",
    width: "100%",
    "& .MuiInputBase-input": {
        color: "whitesmoke",
        fontWeight: 300,
    }
  },
  inputNum: {
      //   marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: "2rem",
      //   marginLeft: "10px",
      //   marginTop: "-10px",
        width: "100%",
        "& .MuiInputBase-input": {
            color: "whitesmoke",
            fontWeight: 300,
            fontSize: 20,
        }
      },
  iconButton: {
    padding: 10,
  },
  formField: {
      fontWeight: 600,
      color: "white"
  },
  sectionTitle: {
      color: "white",
      textAlign: "center",
  }
}));

const Step2 = props => {
  const classes = useStyles();
  const { user } = useMoralis();

  // const founderAddress = user.attributes.ethAddress

  if (props.currentStep !== 2) {
    return null;
  }

  // if (props.currentStepEscrow !== 1) {
  //   return null;
  // }

  return (
    <>
      <h2 className={classes.sectionTitle} >Deposit ETH into Escrow</h2>
      <FormGroup className="FormGroupCon">
        <Label for="email" className={classes.formField}>Confirm your Address</Label>
          <br></br>
          <TextField
              autoFocus
              type="text"
              name="email"
              id="email"
              className={classes.input}        
              placeholder="Enter a project title..."
              value={user.attributes.ethAddress} // Prop: The username input data
              // renderValue={user.attributes.ethAddress.substring(0, 4) + "..." + user.attributes.ethAddress.substring(user.attributes.ethAddress.length-4)}
              inputProps={{ className: classes.input }}
              onChange={props.handleChange} // Prop: Puts data into the state
              required
          />
          <br></br>

        {/* <Label for="founderAddress">Founder Address</Label>
        <Input
          type="text"
          name="founderAddress"
          id="founderAddress"
          placeholder="Enter your Username"
          
          onChange={props.founderAddress} // Prop: Puts data into the state
        /> */}
        <p>If this is correct, continue...</p>
        <button>Hello World</button>
        <sendToEscrowProgressBar />
      </FormGroup>
    </>
  );
};

export default Step2;
