import React, { useState, useEffect } from 'react'
import './App.css';
import { 
  Switch, 
  Route, 
  // useParams,
  BrowserRouter as Router } from 'react-router-dom'
import Loading from "./components/Loading/Loading";
import Home from './pages';
import SignInPage from './pages/signin';
import SignUpPage from './pages/signup';
import Form__JoinWaitlist from './pages/Form__JoinWaitlist';
import Form__Support from './pages/Form__Support';
import ProfilePage from './pages/profile';
import RequestHelpPage from './pages/help';
import PrivacyPolicy from './pages/Legal__PrivacyPolicy'
import TermsOfService from './pages/Legal__TermsOfService'
import Redirect__Discord from './pages/Redirect__Discord'
// import ProfilePublic from './components/Profile__Public';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500)
  })


  useEffect(() => { 
    setIsLoading(true)
    // fetch api and when get response stop loading
    setTimeout(() => {
      setIsLoading(false)
    }, 8000)
  }, []);

  // function Child() {
  //   let { id } = useParams();
  //   return (
  //     <div>
  //       <h2>ID: {id} </h2>
  //     </div>
  //   )
  // }

  return (
    <React.Fragment>
    <Router>
      <Switch>
      {isLoading===true?
        <Loading/>
        :
        <>
        <Route exact path="/" component={Home} />
        <Route path="/signin" component={SignInPage} />
        <Route path="/signup" component={SignUpPage} />
        <Route path="/signupform" component={Form__JoinWaitlist} />
        <Route path="/help" component={RequestHelpPage} />
        <Route path="/profile" component={ProfilePage} />
        <Route path="/support" component={Form__Support} />
        <Route path="/legal/privacy" component={PrivacyPolicy} />
        <Route path="/legal/terms" component={TermsOfService} />
        <Route path="/discord" component={Redirect__Discord} />
        
        </>
      }
      </Switch>
    </Router>
    {/* <Router>
      <Route path="/:uid" component={<ProfilePublic />} />
    </Router> */}
     
  
    </React.Fragment>
  );
}

export default App;
