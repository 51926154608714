export const homeObjectOne = {
    id: 'about',
    lightBackground: false,
    lightText: true,
    lightTextDes: true,
    topLine: 'Developer Marketplace',
    headline: 'Unlimited Project Listings',
    description: 'Join an exclusive (and inclusive) global community of developers helping developers. With an unlimited number of listings per account, your target deadline is right around the corner.',
    buttonLabel: 'Get started',
    imgStart: false,
    img: require('../../assets/images/svg-1.svg').default,
    alt: 'collaboration',
    dark: true,
    primary: true,
    darkText: false,
}

export const homeObjectTwo = {
    id: 'about',
    lightBackground: true,
    lightText: true,
    lightTextDes: true,
    topLine: 'Elite Open-Sourced Talent',
    headline: 'Global Top-Tier Talent',
    description: 'Instead of letting your project sit in your MyDocuments, benefit from a globally sourced pool of top-tier talent. From Hackathon-Winners to FAANG developers, see who can help you on your developer journey today.',
    buttonLabel: 'Get started',
    imgStart: true,
    img: require('../../assets/images/svg-2.svg').default,
    alt: 'collaboration',
    dark: true,
    primary: true,
    darkText: false,
}

export const homeObjectThree = {
    id: 'about',
    lightBackground: false,
    lightText: true,
    lightTextDes: true,
    topLine: 'Scaling Global Entrepreneurship',
    headline: 'Launch Your Side Project to the Moon',
    description: 'The world\'s unicorn companies ($1B+ valuations) are now collectively valued at $3T dollars. This is up by $1T in the past year. With the right developer on your team, you can add your app to this list in no time.',
    buttonLabel: 'Get started',
    imgStart: false,
    img: require('../../assets/images/svg-3.svg').default,
    alt: 'collaboration',
    dark: true,
    primary: true,
    darkText: false,
}

export const homeObjectFour = {
    id: 'start',
    lightBackground: false,
    lightText: true,
    lightTextDes: true,
    topLine: 'Join HelpMeDev Today',
    headline: 'Create an Account Fast and Receive Instant Value',
    description: 'Through tbe power of decentralization, community, and innovation, HelpMeDev will change how we work on projects and launch the companies of tomorrow, today.',
    buttonLabel: 'Sign Up Today',
    imgStart: false,
    img: require('../../assets/images/svg-4.svg').default,
    alt: 'collaboration',
    dark: true,
    primary: true,
    darkText: false,
}