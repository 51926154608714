import React, {useState } from 'react'
import Footer from '../components/Footer'
// import { BrowserRouter as Router } from 'react-router-dom'
import HeroSection from '../components/HeroSection'
import InfoSection from '../components/InfoSection'

import { homeObjectOne, homeObjectTwo, homeObjectThree, homeObjectFour } from '../components/InfoSection/Data'
import DBInfo from '../components/InfoSection/indexDataUsers'
import { homeObjectUsers } from '../components/InfoSection/DataUsers'
import NavBar from '../components/NavBar'
// import NavBarLoggedIn from '../components/NavBar/indexLoggedIn'
import Services from '../components/Services'
import Listings from '../components/Listings'
import SideBar from '../components/SideBar'

import Alert from '@mui/material/Alert';
import { useMoralis } from "react-moralis";

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    // const { authenticate, isAuthenticated, user } = useMoralis();
    // const { authenticate } = useMoralis();
    const { isAuthenticated } = useMoralis();
    // const { auth, authError, logout, isAuthenticating } = useMoralis();
    const { authError } = useMoralis();


    if (!isAuthenticated) {
    return (
        <>
        <div style={{marginTop: 85}}>
        {authError && (
            <Alert variant="outlined" severity="error">
                <strong
                style={{color: "#FF7373"}}
                >{authError.message}</strong>
            </Alert>
          
            )}    

            {/* <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                This is a success alert — <strong>check it out!</strong>
            </Alert> */}
        </div>
            <SideBar isOpen={isOpen} toggle={toggle}/>
            <NavBar toggle={toggle} />
            <HeroSection />
            <DBInfo {...homeObjectUsers} />
            <InfoSection {...homeObjectOne} />
            <InfoSection {...homeObjectTwo} />
            <InfoSection {...homeObjectThree} />
            <Services />
            <InfoSection {...homeObjectFour} />
            <Footer />
        </>
    )}
    if (isAuthenticated) {
    return (
        <>
        <SideBar isOpen={isOpen} toggle={toggle}/>
        <NavBar toggle={toggle} />
          <Listings />
        <Footer />
        </>
        )   
    }
}

export default Home
