import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Icon from '@mui/material/Icon';
import './FilterBox.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      height: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Angular',
  'Assembly Language',
  'AWS',
  'C',
  'C#',
  'C++',
  'Docker',
  'Firebase',
  'Go',
  'Java',
  'JavaScript',
  'Kotlin',
  'MATLAB',
  '.NET',
  'NodeJS',
  'Objective-C',
  'Perl',
  'PHP',
  'Python',
  'ReactJS',
  'React Native',
  'Ruby',
  'Scala',
  'Solidity',
  'SQL',
  'Swift',
  'TypeScript',
];

export default function MultipleSelectCheckmarks() {
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }} >
        <InputLabel 
        id="demo-multiple-checkbox-label"  
        style ={{
            color: "white",
            marginTop: "-12px",
          }}
          // onfocus="this.placeholder = ''"
          // floatLabel="never"
        >Choose Specialties<Icon color="primary"></Icon>
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          style ={{
            color: "white",
            height: "40px",
            border: "1px solid #436587",
          }}
        >
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox 
              style ={{
                      color: "#00e676",
                    }}
                    label='My checkbox' 
                    
              checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}