import styled from 'styled-components';
import './indexDataUsers.css';

export const InfoContainer = styled.div`
    color: #000C30;
    background: ${({lightBackground}) => (lightBackground ? '#0045AB' : '#000C30')};

    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 360px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;

    @media screen and (max-width: 100vw) {
    margin-top: 100px;
    }

    @media screen and (max-width: 777px) {
    margin-top: 0px;
    margin-bottom: 100px;
    height: 760px;
    }
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({ imgStart }) => imgStart ? `'col3 col2 col1'` : `'col1 col2 col3'`};

    @media screen and (max-width: 768px) {
        /* If imgStart true...make col1 the image */
        grid-template-areas: ${({ imgStart }) => imgStart ? `'col1 col1' 'col2 col2' 'col3 col3'` : `'col1 col1' 'col2 col2' 'col3 col3'`}
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`;

export const Column3 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col3;
`;

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
`;

export const TopLine = styled.p`
    color: #43A1FF;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};

    @media screen and (max-width: 480px) {
        font-size: 32px
    }
`;

export const Subtitle = styled.p`
    max-width: 440px;
    margin-top: 50px;
    margin-bottom: 45px;
    font-size: 100px;
    line-height: 27px;
    color: ${({darkText}) => (darkText ? '#010606' : "#fff")};

    @keyframes cursor-blink {
        0% {
            opacity: 0;
        }
        };

    &:after {
        content: "";
        width: 10px;
        height: 70px;
        margin-left: 10px;
        background: white;
        display: inline-block;
        opacity: 0.2;
        animation: cursor-blink 1.8s steps(2) infinite;
    };
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-conent: flex-start;
    
`;

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0px;
`;

