import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Logo from "../brand/HelpMeDev__Logo1.png"
import NavBar from '../components/NavBar';
import SideBar from '../components/SideBar';
import '../App.css'

const TypeFormPage = () => {
    const [isOpen, setIsOpen] = useState(false);

    // useEffect(() => {
    //     window.location.href = "https://discord.com/invite/3BUECGzp9q";
    //   }, []);
    function openTab() {
        window.open('https://discord.com/invite/3BUECGzp9q');
    }
    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
        <SideBar isOpen={isOpen} toggle={toggle}/>
        <NavBar toggle={toggle} />
            
            <center>
            <img 
            src={Logo} 
            alt="HelpMeDev logo"
            style={{width: 70, marginTop: "120px"}}
            ></img>
            <h1 style={{color: "white", marginTop: "20px"}}>Join the HelpMeDev Discord</h1>
            <h3 style={{color: "white", marginTop: "20px", marginBottom: "20px"}}>Click Me:</h3>
            <Link onClick={openTab}></Link>
            {/* <img 
            src="https://cdn.icon-icons.com/icons2/2108/PNG/512/discord_icon_130958.png" 
            alt="Discord Icon"
            style={{width: 300, marginTop: "20px"}}
            onClick="https://discord.com/invite/3BUECGzp9q"
            ></img> */}

            <a 
            href="https://discord.com/invite/3BUECGzp9q" 
            rel="noreferrer" 
            alt="discordlink" 
            target="_blank">
                <img 
                class="DiscordPulse"
                src="https://discord.com/api/guilds/898629527423578133/widget.png?style=banner4"
                alt="DiscordIcon"
                > 
                </img>
            </a>
           
            </center>
        </>
    )
}

export default TypeFormPage
