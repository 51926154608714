import React, { useState, useEffect } from 'react';

import { ListingsSearchContainer, SearchFilterCon, SearchFilterParamsCon, SearchFilterParamsTime, SearchFilterParamsType, SearchFilterParamsExpertise, ServicesContainer, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP, CostP, AddressP, ServicesContainerTop, HelpThisDevBtn, ProgrammingLanguageIconCon, MintProjectBtn } from './ListingsElements'

import Modal from "./components/ListProjectModal"
// import ListItem from './ListItem'

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import EthereumSVG from "../../assets/images/ethereum.svg";

import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

import PL__AWS from "../../assets/programming_logos/aws.png";
import PL__React from "../../assets/programming_logos/React-logo.png";
// import PL__JavaScript from "../../assets/programming_logos/JavaScript-logo.png";
import PL__NodeJS from "../../assets/programming_logos/NodeJS.png";
import PL__Solidity from "../../assets/programming_logos/Solidity_logo.jpeg";
import PL__Python from "../../assets/programming_logos/Python-logo.png";
import PL__Azure from "../../assets/programming_logos/azure.png";


import FilterByRadio from "./components/FilterByTop3KPIs"
import FilterByLang from "./components/FilterByLang"
import FilterByScope from "./components/FilterByScope"

import { useMoralis } from "react-moralis";
// import ProfileSection from '../Profile';



const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 7px #44b700`,
      '&::after': {
        position: 'absolute',
        top: -6,
        left: -6,
        bottom: 0,
        width: '140%',
        height: '140%',
        borderRadius: '50%',
        animation: 'ripple 1.6s infinite ease-in-out',
        border: '4px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));


const useStyles = makeStyles((theme) => ({
    SearchBar: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
  }));

  

const Listings = () => {
    const classes = useStyles();

    // const {user } = useMoralis();
    // const [username] = useState(user.attributes.username);
    // const [username, setUsername] = useState();
    const [projectListings, setProjectListings] = useState([]);
    const [initialFetchComplete, setInitialFetchComplete] = useState(false)
    
    const { 
        // isAuthenticated, 
        user, 
        // setUserData, 
        // userError, 
        // isUserUpdating, 
        isInitialized, 
        Moralis, 
    } = useMoralis();

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        // Toggle back and forth
        setShowModal(prev => !prev);
    };


// console.log([projectListings])


// useEffect Hook to PUT object into Database
useEffect(() => {
    if (!isInitialized) return;
    if (!initialFetchComplete) return;

    let subscription;

    const fetchQuery = async () => {
        const projectListing = Moralis.Object.extend("ProjectListing");
        const query = new Moralis.Query(projectListing);
        query.equalTo("createdBy", user.id);
        subscription = await query.subscribe();

        subscription.on("create", (object) => {
            // when nrew profile added...
            console.log("An object was created by YOU");
            console.log("here is that object", object.attributes);
            console.log(projectListings);
            
            setProjectListings([
                ...projectListings,
                {
                    id: object.id,
                    ...object.attributes,
                },
            ]);
        });
    };

    fetchQuery();

    //Cleanup functions
    return () => {
        subscription.unsubscribe();
    };
    }, [Moralis, isInitialized, initialFetchComplete, user, projectListings]);


  // useEffect Hook to GET objects from Database
  useEffect(() => {
      if (!isInitialized || initialFetchComplete) return;

      const projectListing = Moralis.Object.extend("ProjectListing");
      const query = new Moralis.Query(projectListing);
    //   query.equalTo("createdBy", user.id);
    query.find();

      query.find().then(
          async (results) => {
              setProjectListings(
                  results.map((result) => ({
                      id: result.id,
                      ...result.attributes,
                  }))
              );

              setInitialFetchComplete(true);
          },
          (error) => {
              //object not retrieved successfully
              alert(error.message);
              // error is a Moralis.Error w/ error code and message
          }
      );

  }, [Moralis, isInitialized, initialFetchComplete, user]);

//   const addProjectListingToMoralis = () => {
//       const ProjectListing = Moralis.Object.extend("ProjectListing");
//       const projectListing = new ProjectListing();

//       projectListing
//           .save({
//               title: "Front-end developer for a Shopify Project",
//               description: "Looking for my Wozniak! Really great idea for uber of healthcare. Let's scale!",
//               createdByID: user.id,
//               createdByIDAddress: "0x39B4C4291Aeb0361F05016be3E49bDfFdbC52090",
//               needMostLanguage: "ReactJS",
//               needAlsoLanguages: "AWS, NodeJS, Python",
//               price: 2.5,
//               dueDate: "11/22/2022",
//               createdBy: user.id,
//           })
//           .then(
//               (projectListing) => {
//                   alert("New object created with objectId: " + projectListing.id);
//               },
//               (error) => {
//                   alert("Failed to create new object, with error code: " + error.message);
//               }
//           );
//   };

    

    return (
        <div id="listings">
        <ListingsSearchContainer>

            <SearchFilterCon>
                <Paper component="form" className={classes.SearchBar}>
                    <InputBase
                    className={classes.input}
                    placeholder="Search Listed Projects..."
                    inputProps={{ 'aria-label': 'Search Sounds...' }}
                    />
                    <IconButton type="submit" className={classes.iconButton} aria-label="search">
                    <SearchIcon />
                    </IconButton>
                </Paper>
            </SearchFilterCon>
            <SearchFilterParamsCon id="fadeshow1">
                <SearchFilterParamsTime id="fadeshow1">Filter By:
                    <FilterByRadio />
                </SearchFilterParamsTime>

                <SearchFilterParamsType id="fadeshow1">Filter By Language:
                    <FilterByLang />
                </SearchFilterParamsType >

                <SearchFilterParamsExpertise id="fadeshow1">Filter By Scope:
                    <FilterByScope />
                </SearchFilterParamsExpertise>
            </SearchFilterParamsCon>
            <br></br>
            <br></br>
            <br></br>
            <MintProjectBtn 
                onClick={openModal}
                smooth={true} 
                duration={500} 
                spy={true} 
                exact='true' 
                > List a Project to HelpMeDev
            </MintProjectBtn>
            <Modal showModal={showModal} setShowModal={setShowModal} />
            {/* <button onClick={addProfileToMoralis}>List Project to HelpMeDev</button> */}

        </ListingsSearchContainer>
        <ServicesContainer>
            
            <ServicesWrapper>
            {projectListings.map((projectListing) => (
            
                    
                    <ServicesCard>
                        <ServicesIcon>
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant="dot"
                            >
                            <Avatar
                                sx={{ 
                                    bgcolor: "#43A1FF",
                                    width: "60px",
                                    height: "60px",
                                    marginTop: "30px",
                                    fontSize: "2.25rem",
                                    zIndex: 1,
                                
                                }}
                                alt="HelpMeDev"
                                src="/broken-image.jpg"
                                name={projectListing.submittedByUsername}
                                // name={user.attributes.username}
                                >{projectListing.submittedByUsername.substring(0,1)}

                            </Avatar>
                            </StyledBadge>
                        </ServicesIcon>
                        <ServicesH2>{projectListing.title}</ServicesH2>
                        <ServicesP>{projectListing.description}</ServicesP>
                        <AddressP>
                            <b style={{
                                    fontSize: "1.4rem",
                                    
                                }}>Submitted By:
                            </b> 
    
                            <b style={{
                                    fontSize: "1.rem",
                                    marginLeft: "5px",
                                    fontWeight: 200,
                                }}>
                                    {/* TESTING */}
                                    {/* {projectListing.createdByIDAddress} */}

                                    {/* PROD VERSION 4...4 */}
                                    {projectListing.createdByIDAddress.substring(0, 6) + "..." + projectListing.createdByIDAddress.substring(projectListing.createdByIDAddress.length-4)}
                                    </b>
                            
                        </AddressP>
                        <CostP>
                            <b style={{
                                    fontSize: "1.4rem",
                                    
                                }}>Price:</b> 
                                <img 
                                src={EthereumSVG} alt="Ethereum"
                                style={{
                                    height: "25px",
                                    marginLeft: "10px"
                                }}
                                />
                            <b style={{
                                    fontSize: "2rem",
                                    marginLeft: "5px",
                                    fontWeight: 200,
                                }}>{projectListing.price}</b>
                        </CostP>
    
                        
                        <CostP>
                            <b style={{
                                    fontSize: "1.4rem",
                                    
                                }}>Needs:
                            </b> 
                            <ProgrammingLanguageIconCon>
                                {/* PRIMARY PROGRAMMING LANGUAGE */}
                                {projectListing.needMostLanguage === "AWS" ?  <img src={PL__AWS} alt="programming langauge" height="40px"></img> : <p></p>}
                                {projectListing.needMostLanguage === "Azure" ?  <img src={PL__Azure} alt="programming langauge" height="40px"></img> : <p></p>}
                                {projectListing.needMostLanguage === "NodeJS" ?  <img src={PL__NodeJS} alt="programming langauge" height="40px"></img> : <p></p>}
                                {projectListing.needMostLanguage === "Python" ?  <img src={PL__Python} alt="programming langauge" height="40px"></img> : <p></p>}
                                {projectListing.needMostLanguage === "ReactJS" ?  <img src={PL__React} alt="programming langauge" height="40px"></img> : <p></p>}
                                {projectListing.needMostLanguage === "Solidity" ?  <img src={PL__Solidity} alt="programming langauge" height="40px"></img> : <p></p>}
                                
                                
                                {/* {<h1 style={{fontWeight: "100"}}> + </h1>} */}

                                {/* SECONDARY LANGUAGES */}
                                {projectListing.needAlsoLanguages === null ?  <p></p> : <p></p>}
                                {projectListing.needAlsoLanguages === "AWS" ?  <img src={PL__AWS} alt="programming langauge" height="40px"></img> : <p></p>}
                                {projectListing.needAlsoLanguages === "Azure" ?  <img src={PL__Azure} alt="programming langauge" height="40px"></img> : <p></p>}
                                {projectListing.needAlsoLanguages === "NodeJS" ?  <img src={PL__NodeJS} alt="programming langauge" height="40px"></img> : <p></p>}
                                {projectListing.needAlsoLanguages === "Python" ?  <img src={PL__Python} alt="programming langauge" height="40px"></img> : <p></p>}
                                {projectListing.needAlsoLanguages === "ReactJS" ?  <img src={PL__React} alt="programming langauge" height="40px"></img> : <p></p>}
                                {projectListing.needAlsoLanguages === "Solidity" ?  <img src={PL__Solidity} alt="programming langauge" height="40px"></img> : <p></p>}

                                {/* THIRD LANGUAGES */}
                                {projectListing.needAlsoLanguages2 === null ?  <p></p> : <p></p>}
                                {projectListing.needAlsoLanguages2 === "AWS" ?  <img src={PL__AWS} alt="programming langauge" height="40px"></img> : <p></p>}
                                {projectListing.needAlsoLanguages2 === "Azure" ?  <img src={PL__Azure} alt="programming langauge" height="40px"></img> : <p></p>}
                                {projectListing.needAlsoLanguages2 === "NodeJS" ?  <img src={PL__NodeJS} alt="programming langauge" height="40px"></img> : <p></p>}
                                {projectListing.needAlsoLanguages2 === "Python" ?  <img src={PL__Python} alt="programming langauge" height="40px"></img> : <p></p>}
                                {projectListing.needAlsoLanguages2 === "ReactJS" ?  <img src={PL__React} alt="programming langauge" height="40px"></img> : <p></p>}
                                {projectListing.needAlsoLanguages2 === "Solidity" ?  <img src={PL__Solidity} alt="programming langauge" height="40px"></img> : <p></p>}



                                
                                

                                
                                {/* {projectListing.needMostLanguage + " " + projectListing.needAlsoLanguages} */}
                                {/* <ProgrammingLanguageIcon src={PL__React} alt="React" />
                                <ProgrammingLanguageIcon src={PL__JavaScript} alt="JavaScript" /> */}
                            </ProgrammingLanguageIconCon>
                        </CostP>
    
                        
                        <HelpThisDevBtn 
                            to="#"
                            smooth={true} 
                            duration={500} 
                            spy={true} 
                            exact='true' 
                            >
                            Help This Dev
                        </HelpThisDevBtn>
    
                    </ServicesCard>
                    
              
            ))} 
            </ServicesWrapper>

        </ServicesContainer>

        <ServicesContainerTop>
        
        </ServicesContainerTop>

        <ServicesContainerTop>
        </ServicesContainerTop>

        </div>    
    )
}

export default Listings
