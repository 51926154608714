export const homeObjectUsers = {
    id: 'about',
    lightBackground: false,
    lightText: true,
    lightTextDes: true,
    topLine1: 'Users of HelpMeDev',
    headline1: 'Total App Users',
    description: '6',
    buttonLabel1: 'Sign Up Today',
    imgStart: false,
    img: require('../../assets/images/svg-1.svg').default,

    topLine2: 'Projects on HelpMeDev',
    headline2: 'Total Live Projects',
    description2: 'Join an exclusive (and inclusive) global community of developers helping developers. With an unlimited number of listings per account, your target deadline is right around the corner.',
    buttonLabel2: 'Work On/List a Project',
    imgStart2: false,
    img2: require('../../assets/images/svg-1.svg').default,

    topLine3: 'Community for HelpMeDev',
    headline3: 'Total Discord Members',
    description3: '38',
    buttonLabel3: 'Join the Community',
    imgStart3: false,
    img3: require('../../assets/images/svg-1.svg').default,

    alt: 'collaboration',
    dark: true,
    primary: true,
    darkText: false,
}