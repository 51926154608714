import React, { useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import Logo from "../../../brand/HelpMeDev__Logo1.png"
import ModalPhoto from "../../../assets/images/codeonscreen.jpeg";

// import { withStyles } from "@material-ui/core/styles";
// import Grid from '@mui/material/Grid';
// import FormGroup from "@material-ui/core/FormGroup";
// import FormControl from "@material-ui/core/FormControl";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from '@mui/styles';

import MasterForm from "../../../components/Listings/steps/MainForm"

const useStyles = makeStyles((theme) => ({
    SearchBar: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    input: {
    //   marginLeft: theme.spacing(1),
      flex: 1,
    //   marginLeft: "10px",
      marginTop: "-10px",
      width: "100%",
      "& .MuiInputBase-input": {
          color: "whitesmoke",
          fontWeight: 300,
      }
    },
    inputNum: {
        //   marginLeft: theme.spacing(1),
          flex: 1,
          fontSize: "2rem",
        //   marginLeft: "10px",
        //   marginTop: "-10px",
          width: "100%",
          "& .MuiInputBase-input": {
              color: "whitesmoke",
              fontWeight: 300,
              fontSize: 20,
          }
        },
    iconButton: {
      padding: 10,
    },
    formField: {
        fontWeight: 600,
        color: "white"
    },
    sectionTitleTop: {
        color: "white",
        textAlign: "center",
        fontSize: "28px",
    }
  }));

const Background = styled.div`
    width: 100%;
    height: 200%;
    margin-top: 300px;
    background:rgba(0,0,0,0.8);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    z-index: 999;
    overflow-y: hidden;
`;

const ModalWrapper = styled.div`
    width: 800px;
    height: 500px;
    left: 0%;
    top: 0%;
    /* transform: translate(-5%,-5%); */

    box-shadow: 0 5px 16px rgba(0,0,0,0.2);
    background-image: linear-gradient(to right, #002e5c, #43A1FF);
    color: #000;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 999;
    border-radius: 10px;
    overflow-y: hidden;
`;

const ModalImg = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 10px 0 0 10px;
    background:blue;
    z-index: 999;
    opacity: 50%;
`;

// const ModalImgWrapper = styled.div`
//     color: red;
//     z-index: 1000;
// `

const ModalContent = styled.div`
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.8;
    color: #141414;

    p {
        /* margin-bottom: 1rem; */
    }

    button {
        padding: 10px 24px;
        background: #141414;
        color: #fff;
        border: none;
    }
`;

const CloseModalButton = styled(CloseIcon)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0%;
    z-index: 10;
`

// const CustomColorCheckbox = withStyles({
//     root: {
//       color: "#000",
//       "&$checked": {
//         color: "#000"
//       }
//     },
//     checked: {}
//   })((props) => <Checkbox color="default" {...props} />);
  

const Modal = ({ showModal, setShowModal }) => {
    const classes = useStyles();
    // Background clicks exit Modal
    const modalRef = useRef();

    const closeModal = e => {
        if(modalRef.current === e.target) {
            setShowModal(false)
        }
    };

    const keyPress = useCallback(e => {
        if(e.key === 'Escape' && showModal) {
            setShowModal(false)
        }
    }, [setShowModal, showModal])

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress)
    },
    [keyPress])

    // Spring aniimation
    const animation = useSpring({
        config: {
            duration: 250
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-10%)`,
    })

    // const handleChange = (event) => {
    //     setChecked(event.target.checked);
    //   };

    // const [checked, setChecked] = React.useState(false);

    // Checkbox Group
    // const [
    //     agreements, 
    //     setAgreements
    // ] = React.useState({
    //     terms: true,
    //   });

    // const { terms } = agreements;

    // const handleAgreementChange = (event) => {
    //     setAgreements({ ...agreements, [event.target.name]: event.target.checked });
    //   };

    return (
        <>
            {showModal ? (
                <Background ref={modalRef} onClick={closeModal}>
                    <animated.div style={animation}>
                        <ModalWrapper showModal={showModal}>

                        <ModalImg src={ModalPhoto} alt='code' />

                        <ModalContent>
                            <img src={Logo} alt="HelpMeDev Logo" height="90px" style={{marginTop: "0px"}}></img>
                            <h1 className={classes.sectionTitleTop}>List Project on HelpMeDev</h1>
                            {/* <p>Mint a project & generate an NFT </p> */}
                            {/* <Grid item xs={12}> */}
                                <MasterForm/>
                            {/* </Grid> */}
                            {/* <input placeholder="Title"></input>
                            <input placeholder="Description"></input>
                            <input placeholder="Title"></input>
                            <input placeholder="Username"></input>
                            <input placeholder="Address"></input>
                            <input placeholder="MostNeededLanguage"></input>
                            <input placeholder="needAlsoLanguages"></input>
                            <input placeholder="Price"></input>
                            <input placeholder="DueDate"></input>
                            <form>
                            <FormGroup>
                            <FormControl component="fieldset">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                        <CustomColorCheckbox
                                            checked={checked}
                                            onChange={handleChange}
                                            name="TermsAndConditions"
                                            required
                                        />
                                        }
                                        label="I agree to the Terms & Conditions"
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl>
                            <button 
                            type="submit"
                            // onClick={() => setShowModal (prev => !prev)}
                            >Mint Your Project
                            </button>
                            </FormControl>
                            </FormGroup>
                            </form> */}
                        </ModalContent>
                        <CloseModalButton aria-label='Close modal' onClick={() => setShowModal (prev => !prev)} />
                        </ModalWrapper>
                    </animated.div>
                </Background>
    ) : null};
    </>
    );
};

export default Modal
