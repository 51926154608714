import React, {useState, useEffect} from 'react';
import { Button, ButtonLink } from '../ButtonElements';
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, Column3, TextWrapper, TopLine, Heading, Subtitle, BtnWrap} from './indexDataUsersElements';
import { useMoralis } from "react-moralis";



const DBInfo = ({lightBackground, id, imgStart, topLine1, topLine2, topLine3, lightText, headline1, headline2, headline3, darkText, description, description3,  buttonLabel1, buttonLabel2, buttonLabel3}) => {
    const [numOfUsers, setNumOfUsers] = useState(0);
    const { Moralis } = useMoralis();
    useEffect(() => {
        // .then gets promise
      Moralis.Cloud.run('get_nr_users').then(res=> {
          // JSON.stringify turns response into string
        const users = JSON.stringify(res)
        console.log(users)
        setNumOfUsers(users)
      }).catch(err=> console.log(err))
    });

    return (
        <>
        <InfoContainer lightBackground={lightBackground} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                    <TextWrapper>
                        <TopLine>{topLine1}</TopLine>
                        <Heading lightText={lightText}>{headline1}</Heading>
                        {/* <Subtitle darkText={darkText}>{description}</Subtitle> */}
                        <Subtitle darkText={darkText}>{numOfUsers}</Subtitle>
                        <BtnWrap>
                            <Button to="home"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                            >{buttonLabel1}</Button>
                        </BtnWrap>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <TextWrapper>
                            <TopLine>{topLine2}</TopLine>
                            <Heading lightText={lightText}>{headline2}</Heading>
                            <Subtitle darkText={darkText}>{description}</Subtitle>
                            <BtnWrap>
                                <Button to="home"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact="true"
                                    offset={-80}
                                
                                >{buttonLabel2}</Button>
                            </BtnWrap>
                            </TextWrapper>
                    </Column2>
                    <Column3>
                        <TextWrapper>
                            <TopLine>{topLine3}</TopLine>
                            <Heading lightText={lightText}>{headline3}</Heading>
                            <Subtitle darkText={darkText}>{description3}</Subtitle>
                            <BtnWrap>
                                    <ButtonLink href="https://discord.com/invite/3BUECGzp9q" rel="noreferrer"  target="_blank" aria-label="HelpMeDev Discord Link">{buttonLabel3}</ButtonLink>
                                </BtnWrap>
                            </TextWrapper>
                    </Column3>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
        </>
    )
}
export default DBInfo
