import React from 'react';
import {animateScroll as scroll} from 'react-scroll';
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterLink, SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink} from './FooterElements';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
    
    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle> About Us</FooterLinkTitle>
                                <FooterLink to="/">How HelpMeDev works</FooterLink>
                                <FooterLink to="/">Testimonials</FooterLink>
                                <FooterLink to="/">Careers</FooterLink>
                                <FooterLink to={{ pathname: "https://onepager.vc/helpmedev" }} target="_blank">Investors</FooterLink>
                                <FooterLink to="/legal/terms">Terms of Service</FooterLink>
                                <FooterLink to="/legal/privacy">Privacy Policy</FooterLink>
                        </FooterLinkItems>

                        <FooterLinkItems>
                            <FooterLinkTitle> Contact Us</FooterLinkTitle>
                                <FooterLink to="/">Contact</FooterLink>
                                <FooterLink to="/">Support</FooterLink>
                                <FooterLink to="/">Sponsorships</FooterLink>
                                <FooterLink to="/">Partnerships</FooterLink>
                            </FooterLinkItems>
                    </FooterLinksWrapper>


                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle> Listings</FooterLinkTitle>
                                <FooterLink to="/">Submit Listing</FooterLink>
                                <FooterLink to="/">Find Developers</FooterLink>
                                <FooterLink to="/">Promote Your Project</FooterLink>
                                <FooterLink to="/">Scale Your Business</FooterLink>
                            </FooterLinkItems>

                        <FooterLinkItems>
                            <FooterLinkTitle> Social Media</FooterLinkTitle>
                                <FooterLink to={{ pathname: "https://linkedin.com/company/helpmedev" }} target="_blank">LinkedIn</FooterLink>
                                <FooterLink to={{ pathname: "https://instagram.com/helpmedev" }} target="_blank">Instagram</FooterLink>
                                <FooterLink to={{ pathname: "https://facebook.com/helpmedev" }} target="_blank">Facebook</FooterLink>
                                <FooterLink to={{ pathname: "https://www.youtube.com/channel/UCho9RfaL4_6EPQvdjeIbT6A" }} target="_blank">YouTube</FooterLink>
                                <FooterLink to={{ pathname: "https://twitter.com/helpmedev" }} target="_blank">Twitter</FooterLink>
                            </FooterLinkItems>
                    </FooterLinksWrapper>

                </FooterLinksContainer>

                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>
                            HelpMeDev
                        </SocialLogo>
                        <WebsiteRights>HelpMeDev Ⓒ {new Date().getFullYear()} All rights reserved. </WebsiteRights>
                        <SocialIcons>
                            
                            <SocialIconLink to={{ pathname: "https://linkedin.com/company/helpmedev" }} target="_blank" aria-label="LinkedIn">
                                <LinkedInIcon />
                            </SocialIconLink>

                            <SocialIconLink to={{ pathname: "https://instagram.com/helpmedev" }} target="_blank" aria-label="Instagram">
                                <InstagramIcon />
                            </SocialIconLink>

                            <SocialIconLink to={{ pathname: "https://facebook.com/helpmedev" }} target="_blank" aria-label="Facebook">
                                <FacebookIcon />
                            </SocialIconLink>

                            <SocialIconLink to={{ pathname: "https://www.youtube.com/channel/UCho9RfaL4_6EPQvdjeIbT6A" }} target="_blank" aria-label="YouTube">
                                <YouTubeIcon />
                            </SocialIconLink>

                            <SocialIconLink to={{ pathname: "https://twitter.com/helpmedev" }} target="_blank" aria-label="Twitter">
                                <TwitterIcon />
                            </SocialIconLink>

                        </SocialIcons>
                </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
