import React from 'react'

import {  SearchFilterParamsRadios } from '../ListingsElements'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';


const FilterByRadio = () => {
    const [selectedValue, setSelectedValue] = React.useState('a');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const controlProps = (item) => ({
        checked: selectedValue === item,
        onChange: handleChange,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
      });
    return (
        <div>
            <SearchFilterParamsRadios>
                <FormControl component="fieldset" color="white">
                    <RadioGroup
                        row aria-label="gender"
                        defaultValue="female"
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel color="secondary" value="female" control={<Radio 
                            {...controlProps('a')}
                            sx={{
                            color: "#43A1FF",
                            '&.Mui-checked': {
                                color: "#00F260",
                            },
                            }}
                        />} label="Most Recent" />
                        <FormControlLabel value="recent" 
                        control={<Radio
                            {...controlProps('b')}
                            sx={{
                              color: "#43A1FF",
                              '&.Mui-checked': {
                                color: "#00F260",
                              },
                            }}
                         />} label="Most Popular" />
                        <FormControlLabel value="Value: High to Low" 
                        control={<Radio 
                            {...controlProps('c')}
                            sx={{
                              color: "#43A1FF",
                              '&.Mui-checked': {
                                color: "#00F260",
                              },
                            }}
                        />} label="Value: High to Low" />
                    </RadioGroup>
                </FormControl>
                </SearchFilterParamsRadios>
        </div>
    )
}

export default FilterByRadio
