import React from "react";
import { FormGroup, Label } from "reactstrap";

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
// import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Icon from '@mui/material/Icon';
import { TextField } from "@material-ui/core";
import { makeStyles } from '@mui/styles';

import ETHLogo from "../../../assets/images/ethereum.svg"

const useStyles = makeStyles((theme) => ({
    SearchBar: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    input: {
    //   marginLeft: theme.spacing(1),
      flex: 1,
    //   marginLeft: "10px",
      marginTop: "-10px",
      width: "100%",
      "& .MuiInputBase-input": {
          color: "whitesmoke",
          fontWeight: 300,
      }
    },
    inputNum: {
        //   marginLeft: theme.spacing(1),
          flex: 1,
          fontSize: "2rem",
        //   marginLeft: "10px",
        //   marginTop: "-10px",
          width: "100%",
          "& .MuiInputBase-input": {
              color: "whitesmoke",
              fontWeight: 300,
              fontSize: 20,
          }
        },
    iconButton: {
      padding: 10,
    },
    formField: {
        fontWeight: 600,
        color: "white"
    },
    sectionTitle: {
        color: "white",
        textAlign: "center",
    }
  }));

  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      height: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Angular',
  'Assembly Language',
  'AWS',
  'C',
  'C#',
  'C++',
  'Docker',
  'Firebase',
  'Go',
  'Java',
  'JavaScript',
  'Kotlin',
  'MATLAB',
  '.NET',
  'NodeJS',
  'Objective-C',
  'Perl',
  'PHP',
  'Python',
  'ReactJS',
  'React Native',
  'Ruby',
  'Scala',
  'Solidity',
  'SQL',
  'Swift',
  'TypeScript',

];

const Step1 = props => {
    const classes = useStyles();
    const [needSelection, setNeedSelection] = React.useState([]);

    if (props.currentStep !== 1) {
        return null;
    }

    let currentEthPrice = 4100;

    const handleChangeNeedSelection = (event) => {
      const {
        target: { value },
      } = event;
      setNeedSelection(
        // On autofill we get a the stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    return (
        <>
        <h2 className={classes.sectionTitle} >Build your Project</h2>
        <FormGroup className="FormGroupCon">
            <Label for="email" className={classes.formField}>Title</Label>
            <br></br>
            <TextField
                autoFocus
                type="text"
                name="email"
                id="email"
                className={classes.input}        
                placeholder="Enter a project title..."
                value={props.email} // Prop: The email input data
                inputProps={{ maxLength: 35, className: classes.input }}
                helperText={`${props.email.length}/35`}
                onChange={props.handleChange} // Prop: Puts data into the state
                required
            />
            <br></br>
            <Label for="description" className={classes.formField}>Description</Label>
            <br></br>
            <TextField
                type="text"
                name="description"
                id="description"
                className={classes.input}        
                placeholder="Enter a description..."
                value={props.description} // Prop: The email input data
                inputProps={{ maxLength: 85 }}
                helperText={`${props.description.length}/85`}
                onChange={props.handleChange} // Prop: Puts data into the state
                required
            />
            <br></br>
            <Label for="description" className={classes.formField}>Price</Label>
            <br></br>
            <span style={{display: "flex", marginTop: "-10px"}}>
            <img src={ETHLogo} height="23px" style={{marginTop: "5px", marginRight: "5px"}} alt="ETH Logo"></img>
            <TextField
                type="number"
                step="0.0001"
                name="price"
                id="price"
                className={classes.inputNum}        
                placeholder="2.5"
                value={props.price} // Prop: The email input data
                inputProps={{ maxLength: 4 }}
                helperText={`$ ${props.price*currentEthPrice} USD`}
                onChange={props.handleChange} // Prop: Puts data into the state
                required
            />
            </span>
            
            <Label for="email" className={classes.formField}>Most Important Project Need</Label>
            <br></br>
            <FormControl sx={{ m: 1, width: 300, marginLeft: -0 }} >
              <InputLabel 
              id="demo-multiple-checkbox-label"  
              style ={{
                  color: "white",
                  marginTop: "-12px",
                }}
                // onfocus="this.placeholder = ''"
                // floatLabel="never"
              ><Icon color="primary"></Icon>
            </InputLabel>
                <Select
                  labelId="mostImportantNeedLabel"
                  id="mostImportantNeed"
                  single
                  value={needSelection}
                  onChange={handleChangeNeedSelection}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected}
                  MenuProps={MenuProps}
                  style ={{
                    color: "white",
                    height: "40px",
                    border: "1px solid #436587",
                  }}
                >
              {names.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox 
                  style ={{
                          color: "#00e676",
                        }}
                        label='My checkbox' 
                        
                  checked={needSelection.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
            </FormControl>
            <br></br>
        </FormGroup>
        </>
    );
    };

export default Step1;
