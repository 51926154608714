import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from "react-router-dom";

import { ErrorBox } from '../Error';

import '../../App.css';

import { ProfileContainer, ProfileBackground, ProfileInnerCon, FormButtonSignOut, HeroH1, HeroH1Gradient, HeroP, FormLabel, FormInput, FormButton } from './ProfileElements';

import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { useMoralis } from "react-moralis";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 10px #44b700`,
      '&::after': {
        position: 'absolute',
        top: -9,
        left: -9,
        bottom: 0,
        width: '230%',
        height: '230%',
        borderRadius: '50%',
        animation: 'ripple 1.6s infinite ease-in-out',
        border: '4px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  

  

const ProfileSection = () => {
    // const { authenticate, isAuthenticated } = useMoralis();
    const { logout, isAuthenticating } = useMoralis();
    const { isAuthenticated, user, setUserData, userError, isUserUpdating } = useMoralis();

    const [username, setUsername] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    // const [verified, setVerified] = useState();

    const {location} = useLocation();

   
    // const usernameChar = username.charAt(0);

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
        //   backgroundColor: '#dadde9',
          backgroundColor: '#000',
          backgroundImage: 'linear-gradient(90deg, #ffb347, #ffcc33)',
          color: '#000',
          maxWidth: 220,
          fontSize: theme.typography.pxToRem(14),
          border: '1px solid #dadde9',
        },
      }));

    const handleSave = () => {
        setUserData({
            username,
            email,
            password: password === "" ? undefined : password
        })
    }



    useEffect(() => {
        if (!user) return null;
        setUsername(user.get("username"))
        setEmail(user.get("email"))
      }, [user])

    // function Verified(props) {
    //     // const isVerifiedUser = user.attributes.verified
    //     const isVerified = props.isVerified;
    //     if (isVerified) {
    //         return <IsVerifiedYes />;
    //         }
    //     return <IsVerifiedNo />;
    //         }
        
    // function IsVerifiedYes(props) {
    //     return <h1 className="VerificationCheck">{user.attributes.verified} <VerifiedUserIcon className="VerificationCheck"/></h1>;
    //     }
        
    //     function IsVerifiedNo(props) {
    //     return <h1 className="VerificationCheck">Please sign up.</h1>;
    //     }

    if (isAuthenticated) {
    return (
        <ProfileContainer id="home">
            <ProfileBackground>
                <ProfileInnerCon>
                    <center>
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                    >
                    <Avatar
                        sx={{ 
                            bgcolor: "#43A1FF",
                            width: "80px",
                            height: "80px",
                            marginTop: "30px",
                            fontSize: "2.25rem",
                        
                        }}
                        alt="HelpMeDev"
                        src="/broken-image.jpg"
                        // name={user.attributes.username.charAt(0)}
                        >
                            {/* {user.attributes.username} */}
                            {user.attributes.username.substring(0,1)}
                    </Avatar>
                    </StyledBadge>
                    </center>
                    <HeroH1>Welcome Back,</HeroH1>
                    <HeroH1Gradient className="H1Gradient">{user.attributes.username}{" "} 
                    {/* {user.attributes.verified}  */}
                    <HtmlTooltip
                        title={
                        <React.Fragment>
                            <Typography color="inherit"><VerifiedUserIcon/>{' '}<b>Verified User</b></Typography>
                            {'This user is verified'}
                            {/* {' '}<br></br>{"Read more"}{' '}<u><a href="/legal/terms" alt="terms & conditions" target="_blank" rel="noreferrer" >{"here"}</a></u>{"."} */}
                        </React.Fragment>
                        }
                    >
                        {user.attributes.verified === true ? (
                            <VerifiedUserIcon className="VerificationCheck"/>
                        ) : (
                            <div>{" "}</div>
                        )}
                    </HtmlTooltip>
                    </HeroH1Gradient>
                    {/* <Verified isVerified={true} className="VerificationCheck"/>,
                    {console.log(user.attributes.verified)} */}
                    
            
            <br></br>
            <br></br>
            <center>
            <HeroP>Update your profile here:</HeroP>
            </center>
            <center>
            <FormLabel htmlFor='for'>Username:</FormLabel>
            <FormInput 
                value={username}
                onChange={(event) => setUsername(event.currentTarget.value)}
                />
            </center>
            
            <center>
            <FormLabel htmlFor='for'>Email:</FormLabel>
            <FormInput 
                value={email}
                onChange={(event) => setEmail(event.currentTarget.value)}
                />
            </center>

            <center>
            <FormLabel htmlFor='for'>Password:</FormLabel>
            <FormInput 
                value={password}
                onChange={(event) => setPassword(event.currentTarget.value)}
                />
            </center>
            
            <center>
            <FormButton 
                type='submit'
                onClick={handleSave}
                isLoading={isUserUpdating}
                >
                    Save Profile Changes
            </FormButton>
            {userError && <ErrorBox title="User change has failed" 
            message={userError.message} />}
           
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <FormButtonSignOut 
            type='submit'
            onClick={() => logout()}
            disabled={isAuthenticating}
            >
            Sign Out
            </FormButtonSignOut>
            </center>

            </ProfileInnerCon>
                
            </ProfileBackground> 
        </ProfileContainer>
    )}
    
    if (!isAuthenticated) {
    return (
        <Redirect
        to={{
            pathname: "/",
            state: { from: location }
        }}
        />
    )}
}

export default ProfileSection
